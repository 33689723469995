/* eslint-disable */

import $ from 'jquery';
import async from 'async';
import pdfjsLib from './pdf.js/build/generic/build/pdf';
import Colors from './colors';

/**
 * Internal Dependencies
 */
import { DATATYPE_COLORS, formatDataObject } from '@/utils/use-dataObjects'

const workerSrcPath = './pdf.js/build/generic/build/pdf.worker';
pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrcPath;

const CMAP_URL = './pdf.js/build/generic/web/cmaps/';

// Controls The side margin
const MARGIN_CHUNK = {
	top: 2,
	left: 2,
	bottom: 2,
	right: 2
};

const MARGIN_IMAGE = {
	top: 15,
	left: 15,
	bottom: 15,
	right: 15
};

const CMAP_PACKED = true;
const BORDER_WIDTH = 2; // Need to be an integer
const SELECTED_BORDER_WIDTH = 3; // Need to be an integer
const REMOVED_BORDER_COLOR = false;
const STROKE_DASHARRAY = "10,5"
const HOVER_BORDER_COLOR = 'rgba(0,0,0, 1)';
const SELECTED_BORDER_COLOR = 'rgba(0,0,0, 1)';

// Buffer used to save canvas infos
const CANVAS_CONTEXT_BUFFER = {};
const CANVAS_IMAGE_DATA_BUFFER = {};

// Representation of a chunk in PDF
const Chunk = function(data, scale) {
	this.x = Math.floor((parseFloat(data.x) - MARGIN_CHUNK.left) * scale);
	this.y = Math.floor((parseFloat(data.y) - MARGIN_CHUNK.top) * scale);
	this.w = Math.ceil((parseFloat(data.w) + MARGIN_CHUNK.left + MARGIN_CHUNK.right) * scale);
	this.h = Math.ceil((parseFloat(data.h) + MARGIN_CHUNK.top + MARGIN_CHUNK.bottom) * scale);
	this.p = parseInt(data.p, 10);
	return this;
};

// Representation of a line in PDF
const Line = function(first) {
	this.chunks = [];
	this.h = 0;
	this.w = 0;
	this.yMid = { sum: 0, coeff: 0, avg: 0 };
	this.min = { x: Infinity, y: Infinity };
	this.max = { x: -Infinity, y: -Infinity };
	this.p = undefined;
	if (typeof first !== `undefined`) this.addChunk(first);
	return this;
};

// Get all chunks of this Line
Line.prototype.chunks = function() {
	return this.chunks;
};

// Add chunk to this line
Line.prototype.addChunk = function(input) {
	let chunk = input instanceof Chunk ? input : new Chunk(input),
		xMin = chunk.x,
		xMax = chunk.x + chunk.w,
		yMin = chunk.y,
		yMax = chunk.y + chunk.h;
	if (this.min.x > xMin) this.min.x = xMin;
	if (this.max.x < xMax) this.max.x = xMax;
	if (this.min.y > yMin) this.min.y = yMin;
	if (this.max.y < yMax) this.max.y = yMax;
	this.yMid.sum += (chunk.y + chunk.h / 2) * chunk.w;
	this.yMid.coeff += chunk.w;
	this.yMid.avg = this.yMid.sum / this.yMid.coeff;
	this.w = this.max.x - this.min.x;
	this.h = this.max.y - this.min.y;
	if (typeof this.p === `undefined`) this.p = chunk.p;
	this.chunks.push(chunk);
};

// Check if a chunk is in this line
Line.prototype.isIn = function(chunk) {
	if (this.chunks.length <= 0) return true; // If there is no chunk, it will be "in" by default
	let xMin = chunk.x,
		xMax = chunk.x + chunk.w,
		yMin = chunk.y,
		yMax = chunk.y + chunk.h,
		yMiddle = chunk.y + chunk.h / 2,
		samePage = this.p === chunk.p,
		outY = yMiddle > this.max.y || yMiddle < this.min.y,
		outX = xMax < this.min.x;
	return samePage && !outY && !outX;
};

// Representation of multipes lines in PDF (atteched to a given sentence)
const Lines = function(chunks, scale) {
	this.collection = [new Line()];
	if (Array.isArray(chunks)) this.addChunks(chunks, scale);
	return this;
};

// Get of all lines
Lines.prototype.all = function() {
	return this.collection;
};

// Get last line
Lines.prototype.getLast = function() {
	return this.collection[this.collection.length - 1];
};

// Create a new line
Lines.prototype.newLine = function(chunk) {
	return this.collection.push(new Line(chunk));
};

// Add chunk to this group of lines
Lines.prototype.addChunks = function(chunks = [], scale = 1) {
	for (let i = 0; i < chunks.length; i++) {
		let line = this.getLast(),
			item = chunks[i],
			chunk = new Chunk(item, scale);
		if (line.isIn(chunk)) line.addChunk(chunk);
		else this.newLine(chunk);
	}
};

// Represent an Area
const Area = function(opts, first) {
	this.lines = [];
	this.sentence = { id: opts.sentence.id };
	this.h = 0;
	this.w = 0;
	this.min = { x: Infinity, y: Infinity };
	this.max = { x: -Infinity, y: -Infinity };
	this.p = undefined;
	if (typeof first !== `undefined`) this.addLine(first);
	return this;
};

// Get All lines of this Area
Area.prototype.getLines = function() {
	return this.lines;
};

// Add line to this Area
Area.prototype.addLine = function(line) {
	let xMin = line.min.x,
		xMax = line.min.x + line.w,
		yMin = line.min.y,
		yMax = line.min.y + line.h;
	if (this.min.x > xMin) this.min.x = xMin;
	if (this.max.x < xMax) this.max.x = xMax;
	if (this.min.y > yMin) this.min.y = yMin;
	if (this.max.y < yMax) this.max.y = yMax;
	this.w = this.max.x - this.min.x;
	this.h = this.max.y - this.min.y;
	if (typeof this.p === `undefined`) this.p = line.p;
	this.lines.push(line);
};

// Check if line is next to this Area
Area.prototype.isNext = function(line, margin) {
	if (this.lines.length === 0) return true; // If there is no lines, it will be "next" by default
	let middle = line.min.y + line.h / 2,
		delta = typeof margin !== `undefined` ? margin : line.h / 2,
		samePage = this.p === line.p,
		isTooUnder = middle - delta > this.max.y,
		isTooUpper = middle + delta < this.min.y;
	return samePage && !isTooUpper && !isTooUnder;
};

// Represent a group of Areas
const Areas = function(paragraph, interlines) {
	this.collection = [];
	this.sentence = { id: paragraph.sentence.id };
	this.interlines = interlines;
	this.newArea();
	if (Array.isArray(paragraph.lines)) this.addLines(paragraph.lines);
	return this;
};

// Create a new Area
Areas.prototype.newArea = function(line) {
	return this.collection.push(new Area({ sentence: { id: this.sentence.id } }, line));
};

// Get Last Area
Areas.prototype.getLast = function() {
	return this.collection[this.collection.length - 1];В
};

// Get all Areas
Areas.prototype.all = function() {
	return this.collection;
};

// Add Lines to Areas
Areas.prototype.addLines = function(lines = []) {
	for (let i = 0; i < lines.length; i++) {
		let area = this.getLast(),
			line = lines[i];
		if (area.isNext(line, this.interlines[lines[i].p])) area.addLine(line);
		else this.newArea(line);
	}
};

const PdfViewer = function(id, screenId, events = {}) {
	let self = this;
	this.containerId = id;
	this.screenId = screenId;
	this.viewport = null;
	this.DASPages = [];
	// HTML elements
	this.screen = $(`#${this.screenId}`);
	this.screenElement = this.screen.get(0);
	this.viewerId = id + `Viewer`;
	this.container = $(`#${this.containerId}`);
	this.containerElement = this.container.get(0);
	this.viewer = $(`<div id="${this.viewerId}" class="pdfViewer"></div>`);
	this.viewerElement = this.viewer.get(0);
	this.DAS = $(`#documentView\\.DAS`);
	this.DASElement = this.DAS.get(0);
	this.DASScreen = $(`#documentView\\.DAS\\.screen`);
	this.DASScreenElement = this.DAS.get(0);
	this.DASTextViewer = $(`#documentView\\.DAS\\.screen .textContent`);
	this.DASTextViewerElement = this.DASTextViewer.get(0);
	this.DASViewer = $(`#documentView\\.DAS\\.screen .pdfContent`);
	this.DASViewerElement = this.DASViewer.get(0);
	this.pagesInfo = $(`<div id="${this.viewerId}Info" class="display-right"></div>`);
	this.pagesInfoElement = this.pagesInfo.get(0);
	this.message = $(`<div id="${this.viewerId}Message" class="display-right"></div>`);
	this.messageElement = this.message.get(0);
	this.scrollMarkers = $(`<div id="${this.viewerId}ScrollMarkers" class="display-right"></div>`);
	this.scrollMarkersElement = this.scrollMarkers.get(0);
	this.scrollMarkersButtonsTop = $(`<div id="${this.viewerId}ScrollMarkersButtonsTop" class="display-right"></div>`);
	this.scrollMarkersButtonsTopElement = this.scrollMarkersButtonsTop.get(0);
	this.scrollMarkersButtonsBottom = $(`<div id="${this.viewerId}ScrollMarkersButtonsBottom" class="display-right"></div>`);
	this.scrollMarkersButtonsBottomElement = this.scrollMarkersButtonsBottom.get(0);

	this.buttonFirstPage = $(`<button id="${this.viewerId}ScrollMarkersButtonFirstPage" class="display-right">↑</button>`);
	this.buttonFirstPageElement = this.buttonFirstPage.get(0);
	this.buttonPreviousPage = $(`<button id="${this.viewerId}ScrollMarkersButtonPreviousPage" class="display-right">˄</button>`);
	this.buttonPreviousPageElement = this.buttonPreviousPage.get(0);
	this.buttonNextPage = $(`<button id="${this.viewerId}ScrollMarkersButtonNextPage" class="display-right">˅</button>`);
	this.buttonNextPageElement = this.buttonNextPage.get(0);
	this.buttonLastPage = $(`<button id="${this.viewerId}ScrollMarkersButtonLastPage" class="display-right">↓</button>`);
	this.buttonLastPageElement = this.buttonLastPage.get(0);

	this.initPagesInfo();

	this.buttonFirstPageElement.onclick = function() {
		return self.renderPage({ numPage: 1 }, function(err, numPage) {
			if (err) return console.log(err);
			let scrollTop = self.scrollToPage(numPage);
			if (typeof self.events.onFirstPageButtonClick === `function`)
				return self.events.onFirstPageButtonClick({ scrollTop: scrollTop, numPage: numPage });
		});
	};
	this.buttonPreviousPageElement.onclick = function() {
		return self.renderPages([self.currentPage - 2, self.currentPage - 1], function(err, res) {
			if (err || res[1].err) return console.log(err);
			let numPage = res[1].res;
			let scrollTop = self.scrollToPage(numPage + 1);
			if (typeof self.events.onPreviousPageButtonClick === `function`)
				return self.events.onPreviousPageButtonClick({ scrollTop: scrollTop, numPage: numPage });
		});
	};
	this.buttonNextPageElement.onclick = function() {
		return self.renderPages([self.currentPage + 2, self.currentPage + 1], function(err, res) {
			if (err || res[1].err) return console.log(err);
			let numPage = res[1].res;
			let scrollTop = self.scrollToPage(numPage + 1);
			if (typeof self.events.onNextPageButtonClick === `function`)
				return self.events.onNextPageButtonClick({ scrollTop: scrollTop, numPage: numPage });
		});
	};
	this.buttonLastPageElement.onclick = function() {
		let pages = self.getPages();
		return self.renderPage({ numPage: pages[pages.length - 1] }, function(err, numPage) {
			if (err) return console.log(err);
			let scrollTop = self.scrollToPage(numPage + 1);
			if (typeof self.events.onLastPageButtonClick === `function`)
				return self.events.onLastPageButtonClick({ scrollTop: scrollTop, numPage: numPage });
		});
	};

	this.scrollMarkersButtonsTop.append(this.buttonFirstPage).append(this.buttonPreviousPage);
	this.scrollMarkersButtonsBottom.append(this.buttonNextPage).append(this.buttonLastPage);

	this.container
		.append(this.pagesInfo)
		.append(this.message)
		.append(this.viewer);
	this.scrollMarkersCursor = $(`<span class="cursor"></span>`);
	this.scrollMarkersCursorElement = this.scrollMarkersCursor.get(0);

	let pos = { y: 0 };

	const mouseDownThumbHandler = function (e) {
		pos = {
			// The current scroll
			top: self.screenElement.scrollTop,
			// Get the current mouse position
			y: e.clientY,
		};
		document.body.style.cursor = 'grabbing';
		self.scrollMarkersCursorElement.style.userSelect = 'none';
		document.addEventListener('mousemove', mouseMoveHandler);
		document.addEventListener('mouseup', mouseUpHandler);
	};

	const mouseMoveHandler = function (e) {
		const scrollRatio = self.screenElement.clientHeight / self.screenElement.scrollHeight;
		const dy = e.clientY - pos.y;
		const scrollTop = pos.top + dy / scrollRatio;
		if (typeof self.events.onCustomScroll === `function`)
			return self.events.onCustomScroll(scrollTop);
	};

	const mouseUpHandler = function () {
		document.removeEventListener('mousemove', mouseMoveHandler);
		document.removeEventListener('mouseup', mouseUpHandler);

		document.body.style.cursor = 'auto';
		self.scrollMarkersCursorElement.style.removeProperty('user-select');
	};

	const trackClickHandler = function (e) {
		const bound = self.scrollMarkersElement.getBoundingClientRect();
		const percentage = (e.clientY - bound.top) / bound.height;
		const scrollTop = percentage * (self.screenElement.scrollHeight - self.screenElement.clientHeight);
		if (typeof self.events.onCustomScrollClick === `function`)
			return self.events.onCustomScrollClick(scrollTop);
	};

	this.scrollMarkersElement.addEventListener('click', trackClickHandler);

	// Attach the `mousedown` event handler
	this.scrollMarkersCursorElement.addEventListener('mousedown', mouseDownThumbHandler);

	this.scrollMarkers.append(this.scrollMarkersCursor); // Add cursor in scroll Markers
	this.screen.append(this.scrollMarkersButtonsTop);
	this.screen.append(this.scrollMarkers);
	this.screen.append(this.scrollMarkersButtonsBottom);
	// pdf properties
	this.pdfLoaded = false;
	this.pdfDocument;
	this.pdfPages = {}; // cached render pages
	this.currentPage = 0;
	this.sentencesMapping = { object: undefined, array: undefined };
	// metadata properties
	this.metadata = {};
	// links properties
	this.links = {};
	// Events
	this.events = events;

	// Flags
	this.isRefreshing = false;
	
	return this;
};

// Get order of appearance of sentences
PdfViewer.prototype.getSentences = function(selectedSentences, lastSentence) {
	let sentences = [lastSentence].concat(selectedSentences),
		min = Infinity,
		max = -Infinity;
	for (let i = 0; i < sentences.length; i++) {
		let index = this.sentencesMapping.array.indexOf(sentences[i].id);
		min = index > -1 && index < min ? index : min;
		max = index > -1 && index > max ? index : max;
	}
	if (min !== Infinity && max !== -Infinity)
		return this.sentencesMapping.array.slice(min, max + 1);
	else return [];
};

// Get order of appearance of sentences
PdfViewer.prototype.getSentencesMapping = function() {
	if (typeof this.sentencesMapping.object !== `undefined`) return this.sentencesMapping.object;
	let result = {},
		sentences = {};
	// Get useful infos about sentences
	for (let page in this.metadata.pages) {
		for (let sentenceid in this.metadata.pages[page].sentences) {
			sentences[sentenceid] = {
				id: sentenceid,
				page: page,
				minY: this.metadata.sentences[sentenceid].pages[page].min.y,
				column: this.metadata.sentences[sentenceid].pages[page].columns[
					this.metadata.sentences[sentenceid].pages[page].columns.length - 1
				]
			};
		}
	}
	// Sort sentences & store result
	Object.values(sentences)
		.sort(function(a, b) {
			if (a.page !== b.page) return a.page - b.page;
			else if (a.column !== b.column) return a.column - b.column;
			else if (a.minY !== b.minY) return a.minY - b.minY;
			else return 0;
		})
		.map(function(sentence, index) {
			result[sentence.id] = index;
		});
	this.sentencesMapping.object = result;
	this.sentencesMapping.array = new Array(Object.keys(result).length);
	for (let key in result) {
		this.sentencesMapping.array[parseInt(result[key], 10)] = key;
	}
	return result;
};

// Get last sentences index
PdfViewer.prototype.getSentencesIndexesLimit = function() {
	return {
		min : this.sentencesMapping.object[this.sentencesMapping.array[0]],
		max: this.sentencesMapping.object[this.sentencesMapping.array[this.sentencesMapping.array.length - 1]]
	};
}

// Render the PDF
PdfViewer.prototype.load = function(pdf, xmlMetadata, cb) {
	console.log(`Loading PDF...`);
	let self = this;
	this.sentencesMapping = pdf.metadata.mapping;
	this.viewer.empty();
	return this.loadPDF(pdf.url, function(err, pdfDocument) {
		if (err) return console.log(err);
		console.log(`Load of PDF done.`);
		self.pdfLoaded = true;
		self.pdfDocument = pdfDocument;
		let metadata = {
			pages: pdf.metadata.pages,
			sentences: pdf.metadata.sentences,
			links: xmlMetadata.links,
			colors: xmlMetadata.colors,
			activeDataObjectType: xmlMetadata.activeDataObjectType
		};
		
		self.metadata = metadata;
		return self.renderPage({ numPage: 1 }, function(err) {
			if (err) return console.log(err);
			return cb(err);
		});
	});
};


PdfViewer.prototype.loadDAS = function(DAS, cb) {
	let self = this;
	this.DASPages = DAS.sentences.map(function(item) {
		return self.getPagesOfSentence(item);
	}).flat().filter(function(item, i, ar) { return ar.indexOf(item) === i });
	this.DASSentences = DAS.sentences.length > 0 ? DAS.sentences : [];
	this.DASContent = DAS.content;
	return self.renderDAS(function(err) {
		return cb(err);
	});
};

// Render the PDF
PdfViewer.prototype.renderDAS = function(cb) {
	console.log(`Rendering DAS...`);
	let self = this;
	this.DASViewer.empty();
	this.DASTextViewer.empty();
	if (this.DASSentences.length <= 0) {
		this.DASTextViewer.append($(`<span>${this.DASContent}</span>`));
		if (!this.DASContent) this.toggleDAS("minimized");
		return cb();
	}
	this.DASTextViewer.hide();
	return this.renderDASPages(this.DASPages, function(err) {
		if (err) console.log(err);
		if (self.DASSentences[0]) self._scrollToDASSentence(self.DASSentences[0]);
		console.log(`Render of DAS done.`);
		return cb(err);
	});
};

// Toggle DAS visibility
PdfViewer.prototype.toggleDAS = function(visibility) {
	const btn = this.DAS.find(`button.btn-minimize`);
	let isMinimized = this.DAS.hasClass("minimized");
	let forceMinimized = visibility === "minimized"
	if (!isMinimized || forceMinimized) {
		this.screen.addClass("noDAS");
		this.DAS.addClass("minimized");
		btn.text(' + ');
	} else {
		this.DAS.removeClass("minimized");
		this.screen.removeClass("noDAS");
		btn.text(' - ');
	}
}

// show message
PdfViewer.prototype.showMessage = function(text, cb) {
	if (text) this.message.text(text);
	this.message.show(`fast`, function() {
		return cb();
	});
};

// Hide message
PdfViewer.prototype.hideMessage = function() {
	this.message.hide(`fast`);
};

// Get Pages of a given dataObject
PdfViewer.prototype.getPagesOfDataObject = function(dataObject) {
	if (
		this.links &&
		Array.isArray(this.links[dataObject._id]) &&
		this.links[dataObject._id].length &&
		typeof this.metadata.sentences[this.links[dataObject._id][0]].pages === `object`
	)
		return Object.keys(this.metadata.sentences[this.links[dataObject._id][0]].pages).map(function(
			item
		) {
			return parseInt(item, 10);
		});
	else return [];
};

// Get Pages of a given sentence
PdfViewer.prototype.getPagesOfSentence = function(sentence) {
	if (this.metadata.sentences && sentence.id)
		return Object.keys(this.metadata.sentences[sentence.id].pages)
			.map(function(item) {
				return parseInt(item, 10);
			})
			.sort();
	else return [];
};

// Get Pages
PdfViewer.prototype.getPages = function() {
	if (this.pdfDocument) return Array.from({ length: this.pdfDocument.numPages }, (_, i) => i + 1);
	else return [];
};

// Get Pages
PdfViewer.prototype.getLoadedPages = function() {
	const loadedPages = document.querySelectorAll('#pdfViewer div[data-loaded="true"]');
	if (!loadedPages) return 
	
	return [...loadedPages].map(node => parseInt(node.getAttribute('data-page-number'), 10))
};

// Insert Pages
PdfViewer.prototype.insertPage = function(numPage, page) {
	// delete older version of this page
	$(`#pdfViewer div[data-page-number="${numPage}"]`).remove();
	let inserted = false,
		previous;
	for (let i = numPage - 1; i >= 0; i--) {
		previous = $(`#pdfViewer div[data-page-number="${i}"]`);
		if (previous.get(0)) {
			inserted = true;
			previous.after(page);
			break;
		}
	}
	if (!inserted) this.viewerElement.appendChild(page);
};

// Insert Pages
PdfViewer.prototype.insertDASPage = function(numPage, page) {
	// delete older version of this page
	this.DASViewer.find(`div[data-page-number="${numPage}"]`).remove();
	let inserted = false,
		previous;
	for (let i = numPage - 1; i >= 0; i--) {
		previous = this.DASViewer.find(`div[data-page-number="${i}"]`);
		if (previous.get(0)) {
			inserted = true;
			previous.after(page);
			break;
		}
	}
	if (!inserted) this.DASViewerElement.appendChild(page);
};

// Get PdfPage
PdfViewer.prototype.getPdfPage = function(numPage, cb) {
	let self = this;
	if (this.pdfPages && this.pdfPages[numPage]) return cb(null, this.pdfPages[numPage]);
	else
		return this.pdfDocument
			.getPage(numPage)
			.then(function(pdfPage) {
				self.pdfPages[numPage] = pdfPage;
				return cb(null, pdfPage);
			})
			.catch((err) => {
				console.log(err);
				return cb(err);
			});
};

// Get PdfPages
PdfViewer.prototype.getPdfPages = function(viewer, cb) {
	let self = this;
	return async.reduce(
		this.getPages(),
		{},
		function(acc, numPage, callback) {
			return self.getPdfPage(numPage, function(err, pdfPage) {
				if (err) return callback(err);
				let desiredWidth = viewer.offsetWidth,
					viewport_tmp = pdfPage.getViewport({ scale: 1 }),
					the_scale = desiredWidth / viewport_tmp.width,
					viewport = pdfPage.getViewport({ scale: the_scale });
				self.viewport = viewport;
				acc[numPage] = { w: viewport.width, h: viewport.height };
				return callback(err, acc);
			});
		},
		function(err, result) {
			if (err) cb(err);
			return cb(err, result);
		}
	);
};

PdfViewer.prototype.initPagesInfo = function() {
	let self = this;
	this.pagesInfo.empty().append(`<span>Page <input pattern="[0-9]+" id="pdfNumCurrentPage" type="text" value="-1"/> / <span id="pdfNumMaxPage">-1</span><span>`);
	let numPageInput = this.pagesInfo.find("#pdfNumCurrentPage");
	let numPageInputElement = numPageInput.get(0);
	numPageInputElement.addEventListener("keyup", function(event) {
		if (event.keyCode === 13) {
			const numPage = Math.floor(Number(numPageInput.val()));
			if (isNaN(numPage) || numPage > self.pdfDocument.numPages || numPage <= 0) return numPageInput.blur();
			return self.renderPages([numPage - 1, numPage, numPage + 1], function(err) {
				if (err) return console.log(err);
				let scrollTop = self.scrollToPage(numPage);
				if (typeof self.events.onPageNumberSubmit === `function`)
					self.events.onPageNumberSubmit(scrollTop);
				return numPageInput.blur();
			});
		}
	});
	numPageInputElement.addEventListener("focusout", function(event) {
		self.setPage(self.currentPage);
	});
}

// Get PdfPages
PdfViewer.prototype.setPage = function(numPage) {
	let numPageInput = this.pagesInfo.find("#pdfNumCurrentPage");
	let numPageInputElement = numPageInput.get(0);
	numPageInput.val(numPage);
	numPageInputElement.style.width = this.pdfDocument.numPages.toString().length * 12 + "px";
	numPageInputElement.style.maxWidth = this.pdfDocument.numPages.toString().length * 12 + "px";
	this.pagesInfo.find("#pdfNumMaxPage").text(this.pdfDocument.numPages);
};

// hide scroll markers
PdfViewer.prototype.hideMarkers = function() {
	/*this.screen.removeClass('no-scroll');*/
	this.scrollMarkers.hide();
	this.scrollMarkersButtonsTop.hide();
	this.scrollMarkersButtonsBottom.hide();
};

// hide scroll markers
PdfViewer.prototype.showMarkers = function() {
	/*this.screen.addClass('no-scroll');*/
	this.scrollMarkers.show();
	this.scrollMarkersButtonsTop.show();
	this.scrollMarkersButtonsBottom.show();
};

// Refresh scroll cursor
PdfViewer.prototype.refreshScrollCursor = function(scrollInfo) {
	const spanTop = this.screen.scrollTop();
	const spanBottom = spanTop + this.screen.height();
	const markerTop = Math.floor( (spanTop * this.screen.height()) / this.screen.prop(`scrollHeight`) );
	const markerBottom = Math.floor( (spanBottom * this.screen.height()) / this.screen.prop(`scrollHeight`) );
	
	this.scrollMarkersCursorElement.style.top = markerTop + `px`;
	this.scrollMarkersCursorElement.style.height = markerBottom - markerTop + `px`;
};

// Get PdfPages
PdfViewer.prototype.onScroll = function(opts = {}, cb) {
	let direction = opts.direction;
	let self = this;
	if (direction > 0)
		return this.renderNextPage(function(err, res) {
			self.currentPage = self.refreshNumPage();
			self.refreshScrollCursor();
			return cb(err, res);
		});
	return this.renderPreviousPage(function(err, res) {
		self.currentPage = self.refreshNumPage();
		self.refreshScrollCursor();
		return cb(err, res);
	});
};

// Get PdfPages
PdfViewer.prototype.refreshNumPage = function() {
	let	scrollInfo = {
		position: this.screen.scrollTop(),
		height: this.screen.prop(`scrollHeight`)
	};
	let pages = this.viewer.find(`div[class="page"]`).sort(function(a, b) {
			let aPage = parseInt($(a).attr(`data-page-number`), 10),
				bPage = parseInt($(b).attr(`data-page-number`), 10);
			return aPage - bPage;
		}),
		maxHeight = this.viewer.outerHeight(true),
		height = 0,
		coeff = scrollInfo.position / scrollInfo.height,
		numPage = 0;
	for (let i = 0; i < pages.length; i++) {
		let page = pages[i],
			el = $(page);
		height += el.outerHeight(true);
		numPage = parseInt(el.attr(`data-page-number`), 10);
		if (height / maxHeight > coeff) break;
	}
	this.setPage(numPage);
	return numPage;
};

// Load PDF file
PdfViewer.prototype.loadPDF = function(url, cb) {
	let self = this;
	let loadingTask = pdfjsLib.getDocument({
		url: url,
		cMapUrl: CMAP_URL,
		cMapPacked: CMAP_PACKED
	});
	return loadingTask.promise
		.then(function(pdfDocument) {
			return cb(null, pdfDocument);
		})
		.catch(function(err) {
			console.log(err);
			self.container
				.empty()
				.append(`<div>An error has occurred while processing the document</div>`);
			return cb(err);
		});
};

// Render a given page
PdfViewer.prototype.renderUntilPage = function(numPage, cb) {
	// Loading document
	let self = this;
	let memo = [];
	return async.reduce(
		Array.from({ length: numPage }, (_, i) => i + 1),
		memo,
		function(acc, numPage, callback) {
			return self.renderPage({ numPage: numPage }, function(err, res) {
				acc.push({ err, res });
				return callback(err, acc);
			});
		},
		function(err, acc) {
			if (err) console.log(err);
			return cb(err, acc);
		}
	);
};

// Render given pages
PdfViewer.prototype.renderPages = function(numPages, cb) {
	// Loading document
	let self = this;
	let memo = [];
	return async.reduce(
		numPages,
		memo,
		function(acc, numPage, callback) {
			return self.renderPage({ numPage: numPage }, function(err, res) {
				acc.push({ err, res });
				return callback(null, acc);
			});
		},
		function(err, acc) {
			if (err) console.log(err);
			return cb(err, acc);
		}
	);
};

// Render next page (or nothing if all pages already rendered)
PdfViewer.prototype.renderNextPage = function(cb) {
	let self = this;
	let numPage = this.currentPage + 1;
	if (numPage <= this.pdfDocument.numPages && numPage > 0) {
		return this.renderPage({ numPage: numPage }, function(err, numPage) {
			return cb(err, numPage);
		});
	} else return cb(true, new Error("Bad numPage value"));
};

// Render next page (or nothing if all pages already rendered)
PdfViewer.prototype.renderPreviousPage = function(cb) {
	let self = this;
	let numPage = this.currentPage - 1;
	if (numPage <= this.pdfDocument.numPages && numPage > 0) {
		return this.renderPage({ numPage: numPage }, function(err, numPage) {
			return cb(err, numPage);
		});
	} else return cb(true, new Error("Bad numPage value"));
};

// Refresh markers
PdfViewer.prototype.refreshMarkers = function() {
	const self = this;
	const screenHeight = this.screenElement.getBoundingClientRect().height || 0;

	return this.scrollMarkers.find(`span.marker`).map(function() {
		let marker = $(this),
			markerElement = marker.get(0),
			sentenceid = marker.attr(`sentence-id`),
			spanTop = self._scrollToSentence({ id: sentenceid }) + (screenHeight / 2),
			spanBottom = spanTop + parseInt(marker.attr(`contour-height`), 10),
			spanLeft = parseInt(marker.attr(`contour-left`), 10),
			spanRight = spanLeft + parseInt(marker.attr(`contour-width`), 10),
			markerTop = Math.floor(
				(spanTop * self.screen.height()) / self.container.prop(`scrollHeight`)
			),
			markerBottom = Math.floor(
				(spanBottom * self.screen.height()) / self.container.prop(`scrollHeight`)
			),
			markerLeft = Math.floor((spanLeft * self.screen.width()) / self.container.width()),
			markerRight = Math.floor((spanRight * self.screen.width()) / self.container.width()),
			coeff = self.scrollMarkers.outerWidth() / self.container.outerWidth();
		markerElement.style.top = markerTop + `px`;
		markerElement.style.left = parseInt(markerLeft * coeff, 10) + `px`;
		markerElement.style.height = markerBottom - markerTop + `px`;
		markerElement.style.width = parseInt((markerRight - markerLeft) * coeff, 10) + `px`;
	});
	return self.refreshMarkersColor()
};

// Insert data-objects
PdfViewer.prototype.insertDataObjects = function(numPage) {
	let self = this;
	let links = this.metadata.links;
	for (let i = 0; i < links.length; i++) {
		if (
			this.getPagesOfSentence(links[i].sentence).indexOf(numPage) > -1 &&
			this.metadata.colors[links[i].dataObject?._id] &&
			this.metadata.colors[links[i].dataObject?._id].background &&
			this.metadata.colors[links[i].dataObject?._id].background.rgb
		) {
			self.addDataObject(links[i].dataObject, links[i].sentence, false);
		}
	}
};

// Render a given page
PdfViewer.prototype.renderDASPages = function(numPages, cb) {
	// Loading document
	let self = this;
	let memo = [];
	return async.reduce(
		numPages,
		memo,
		function(acc, numPage, callback) {
			return self.renderDASPage({ numPage: numPage }, function(err, res) {
				acc.push({ err, res });
				return callback(null, acc);
			});
		},
		function(err, acc) {
			if (err) console.log(err);
			return cb(err, acc);
		}
	);
};

// Render a given page
PdfViewer.prototype.renderDASPage = function (opts, cb) {
	const self = this;
	const force = !!opts.force;
	const numPage = opts.numPage;

	if (self.isRefreshing && !force) return cb(null, numPage);

	if (typeof numPage === "undefined") return cb(new Error(`numPage required`));

	if (numPage > this.pdfDocument.numPages || numPage < 1) return cb(true, new Error("Bad numPage value"));

	if (!force && this.DASViewer.find(`.page[data-page-number="${numPage}"]`).get(0)) return cb(null, numPage);

	return this.showMessage(`Loading Page ${numPage}...`, function () {
		return self.getPdfPage(numPage, function (err, pdfPage) {
			if (err) {
				self.hideMessage();
				return cb(err);
			}
			let desiredWidth = self.DASViewerElement.offsetWidth,
				viewport_tmp = pdfPage.getViewport({ scale: 1 }),
				the_scale = desiredWidth / viewport_tmp.width,
				viewport = pdfPage.getViewport({ scale: the_scale }),
				page = self.buildEmptyPage(numPage, viewport.width, viewport.height),
				canvas = page.querySelector(`canvas`),
				wrapper = page.querySelector(`.canvasWrapper`),
				textLayerContainer = page.querySelector(`.textLayer`),
				annotationLayer = page.querySelector(`.annotationLayer`),
				canvasContext = canvas.getContext(`2d`, { willReadFrequently: true });
			// Insert page
			self.insertDASPage(numPage, page);
			return pdfPage
				.render({
					canvasContext: canvasContext,
					viewport: viewport
				}).promise.then(function() {
					// Returns a promise, on resolving it will return text contents of the page
					return pdfPage.getTextContent();
				}).then(function (textContent) {
					return pdfjsLib.renderTextLayer({
						textContent: textContent,
						container: textLayerContainer,
						viewport: viewport,
						textDivs: []
					}).promise.then(function () {
						return pdfPage.getAnnotations();
					}).then(function(annotationsData) {
						self.insertDASSentences(the_scale, numPage);
						if (annotationsData.length <= 0) return cb(null, numPage);
						return self.renderAnnotationLayer({
							annotations: annotationsData,
							annotationLayer: annotationLayer,
							viewport: viewport,
							numPage: numPage,
							scale: the_scale
						}, function () {
							self.hideMessage();
							return cb(null, numPage);
						});
					});
				})
				.catch(function (err) {
					self.hideMessage();
					console.log(err);
					return cb(err);
				});
			});
		});
}

// Add sentence
PdfViewer.prototype.renderAnnotationLayer = function(opts, cb) {
	let self = this;
	if (Array.isArray(opts.annotations) && opts.annotations.length > 0) {
		for (let i = 0; i < opts.annotations.length; i++) {
			let annotation = opts.annotations[i];
			if (Array.isArray(annotation.rect) && annotation.subtype === "Link") {
				let rect = opts.viewport.convertToViewportRectangle(annotation.rect);
				let chunk = new Chunk({ x: rect[0], y: rect[3], w: rect[2] - rect[0], h: rect[1] - rect[3], p: opts.numPage }, 1);
				//make events the area
				let section = document.createElement(`section`);
				section.classList.add(`linkAnnotation`);
				section.setAttribute(`tabindex`, 1000);
				section.setAttribute(`data-annotation-id`, annotation.id);
				let	style = `z-index: 1; border-width: ${annotation.borderStyle.width}px; border-style: solid; border-color: rgb(${annotation.color[0]}, ${annotation.color[1]}, ${annotation.color[2]});width:${chunk.w}px; height:${chunk.h}px; position:absolute; top:${chunk.y}px; left:${chunk.x}px; `;
				section.setAttribute(`style`, style);

				let a = document.createElement(`a`);
				a.setAttribute(`data-annotation-id`, annotation.id);
				if (annotation.url) {
					a.setAttribute(`href`, annotation.url);
					a.setAttribute(`target`, `_blank`);
				}
				a.classList.add('annotationDAS');

				section.append(a);
				opts.annotationLayer.append(section);
			}
		}
	}
	return cb();
};

// Render a given page
PdfViewer.prototype.renderPage = function (opts, cb) {
	const self = this;
	const force = !!opts.force;
	const numPage = opts.numPage;

	if (self.isRefreshing && !force) return cb(null, numPage);

	if (typeof numPage === "undefined") return cb(new Error(`numPage required`));

	if (numPage > this.pdfDocument.numPages || numPage < 1) return cb(true, new Error("Bad numPage value"));

	if (!force && this.viewer.find(`.page[data-page-number="${numPage}"]`).get(0)) return cb(null, numPage);

	return this.showMessage(`Loading Page ${numPage}...`, function () {
		return self.getPdfPage(numPage, function (err, pdfPage) {
			if (err) {
				self.hideMessage();
				return cb(err);
			}
			let desiredWidth = self.viewerElement.offsetWidth,
				viewport_tmp = pdfPage.getViewport({ scale: 1 }),
				the_scale = desiredWidth / viewport_tmp.width,
				viewport = pdfPage.getViewport({ scale: the_scale }),
				page = self.buildEmptyPage(numPage, viewport.width, viewport.height),
				canvas = page.querySelector(`canvas`),
				wrapper = page.querySelector(`.canvasWrapper`),
				canvasContext = canvas.getContext(`2d`, { willReadFrequently: true });
			self.viewport = viewport;
			// Insert page
			self.insertPage(numPage, page);
			return pdfPage
				.render({
					canvasContext: canvasContext,
					viewport: viewport
				})
				.promise.then(function () {
					// Build Contours
					let contours = self.buildAreas(the_scale, numPage);
					// Insert Contours
					contours.map(function (contour) {
						self.insertContours(contour);
					});
					// Insert Sentences
					self.insertSentences(the_scale, numPage);
					self.insertDataObjects(numPage);
					// refresh markers scroll
					self.refreshMarkers();
					page.setAttribute(`data-loaded`, `true`);
					self.hideMessage();
					delete CANVAS_CONTEXT_BUFFER[numPage]
					delete CANVAS_IMAGE_DATA_BUFFER[numPage]
					return cb(null, numPage);
				})
				.catch(function (err) {
					self.hideMessage();
					console.log(err);
					return cb(err);
				});
		});
	});
};

// Refresh pdf display
PdfViewer.prototype.refresh = function (cb) {
	const self = this;
	const { screenElement } = self;
	// let screenScroll = screenElement.scrollHeight - screenElement.clientHeight;
	// const scrollPercentage = (100 * screenElement.scrollTop / screenScroll).toFixed(2);
	self.isRefreshing = true;
	screenElement.classList.add('is-loading');

	if (self.pdfDocument) {
		// Loading document
		return async.eachSeries(this.getLoadedPages(),
			function (numPage, callback) {
				return self.renderPage({
					numPage: numPage,
					force: true
				}, function (err, res) {
					return callback(err);
				});
			},
			function (err) {
				if (err) console.log(err);
				// screenScroll = screenElement.scrollHeight - screenElement.clientHeight;
				// screenElement.scrollTop = (screenScroll * scrollPercentage / 100);
				screenElement.classList.remove('is-loading');
				self.refreshDataObjectsColor();
				self.refreshMarkers();
				self.isRefreshing = false;
				return self.renderDAS(function(err) {
					if (err) console.log(err);
					if (typeof cb === `function`) return cb();
				});
			}
		);
	} else {
		if (typeof cb === `function`) return cb(new Error(`PDF cannot be refreshed`));
	}
};

// Build all Areas
PdfViewer.prototype.buildAreas = function(scale, numPage) {
	let paragraphs = [],
		interlines = {},
		result = [];
	if (this.metadata.pages[numPage] && this.metadata.pages[numPage].sentences) {
		for (let sentenceid in this.metadata.pages[numPage].sentences) {
			if (
				this.metadata.sentences[sentenceid] &&
				this.metadata.sentences[sentenceid].areas &&
				this.metadata.sentences[sentenceid].areas.length > 0
			) {
				// Calculate interlines
				this.metadata.sentences[sentenceid].areas.map(function(areas) {
					for (let key in areas.interlines) {
						interlines[key] = areas.interlines[key] * scale;
					}
				});
			}
			// Calculate chunks
			let chunks = this.metadata.sentences[sentenceid].chunks.filter(function(chunk) {
				return parseInt(chunk.p, 10) === numPage;
			});
			let lines = {
				lines: new Lines(chunks, scale).all(),
				sentence: { id: sentenceid }
			};
			paragraphs.push(lines);
		}
		// Create new areas
		for (let i = 0; i < paragraphs.length; i++) {
			result.push(new Areas(paragraphs[i], interlines).all());
		}
	}
	return result;
};

// Insert contours of lines
PdfViewer.prototype.insertContours = function(areas) {
	if (Array.isArray(areas)) {
		for (let i = 0; i < areas.length; i++) {
			this.container
				.find(`.page[data-page-number="${areas[i].p}"] .contoursLayer`)
				.append(this.buildHitBoxes(areas[i]));
		}
		return true;
	} else return null;
};

// Build borders
PdfViewer.prototype.buildHitBoxes = function(area) {
	let container = $(`<div>`);
	let sentenceOverlay = this.getSentenceOverlay(area);
	let hitBoxes = sentenceOverlay.hitBoxes.map(function(item) { return item.elements });
	let borders = sentenceOverlay.borders.elements;
	hitBoxes.map(function(item) {
		return container.append(item);
	});
	borders.map(function(item) {
		return container.append(item);
	});
	container
		.attr(`sentence-id`, area.sentence.id)
		.attr(`page`, area.p)
		.attr(`contour-width`, area.w)
		.attr(`contour-height`, area.h)
		.attr(`contour-top`, area.min.y)
		.attr(`contour-left`, area.min.x)
		.attr(`class`, `contour`)
		.attr('index', this.sentencesMapping.array.indexOf(area.sentence.id));
	return container;
};

// Add sentence
PdfViewer.prototype.insertSentences = function(scale, numPage) {
	let self = this,
		annotationsContainer = this.container.find(
			`.page[data-page-number="${numPage}"] .annotationsLayer`
		);
	if (this.metadata.pages[numPage] && this.metadata.pages[numPage].sentences)
		for (let sentenceid in this.metadata.pages[numPage].sentences) {
			let chunks = this.metadata.sentences[sentenceid].chunks.filter(function(chunk) {
				return parseInt(chunk.p, 10) === numPage;
			});
			for (let i = 0; i < chunks.length; i++) {
				let chunk = chunks[i],
					ch = new Chunk(chunk, scale);
				//make events the area
				let element = document.createElement(`s`),
					attributes = `width:${ch.w}px; height:${ch.h}px; position:absolute; top:${ch.y}px; left:${ch.x}px;`;
				element.setAttribute(`style`, attributes);
				element.setAttribute(`sentence-id`, sentenceid);
				element.setAttribute(`is-pdf`, true);
				element.setAttribute('index', this.sentencesMapping.array.indexOf(sentenceid));
				// the link here goes to the bibliographical reference
				element.onclick = function() {
					let el = $(this);
					if (typeof self.events.onClick === `function`)
						return self.events.onClick({ id: el.attr(`sentence-id`) });
				};
				element.onmouseover = function() {
					let el = $(this);
					if (typeof self.events.onHover === `function`)
						return self.events.onHover({ id: el.attr(`sentence-id`) });
				};
				element.onmouseout = function() {
					let el = $(this);
					if (typeof self.events.onEndHover === `function`)
						return self.events.onEndHover({ id: el.attr(`sentence-id`) });
				};
				annotationsContainer.append(element);
			}
		}
};

// Add sentence
PdfViewer.prototype.insertDASSentences = function(scale, numPage) {
	let self = this,
		annotationsContainer = this.DASViewer.find(
			`.page[data-page-number="${numPage}"] .annotationsLayer`
		);
	if (this.metadata.pages[numPage] && this.metadata.pages[numPage].sentences)
		for (let sentenceid in this.metadata.pages[numPage].sentences) {
			let chunks = this.metadata.sentences[sentenceid].chunks.filter(function(chunk) {
				return parseInt(chunk.p, 10) === numPage;
			});
			for (let i = 0; i < chunks.length; i++) {
				let chunk = chunks[i],
					ch = new Chunk(chunk, scale);
				//make events the area
				let element = document.createElement(`s`),
					attributes = `width:${ch.w}px; height:${ch.h}px; position:absolute; top:${ch.y}px; left:${ch.x}px;`;
				element.setAttribute(`style`, attributes);
				element.setAttribute(`sentence-id`, sentenceid);
				element.setAttribute(`is-pdf`, true);
				element.setAttribute('index', this.sentencesMapping.array.indexOf(sentenceid));
				annotationsContainer.append(element);
			}
		}
};

// Build an empty page
PdfViewer.prototype.buildEmptyPage = function(num, width, height) {
	let page = document.createElement(`div`),
		canvas = document.createElement(`canvas`),
		wrapper = document.createElement(`div`),
		textLayer = document.createElement(`div`),
		annotationsLayer = document.createElement(`div`),
		annotationLayer = document.createElement(`div`),
		contoursLayer = document.createElement(`div`);

	page.className = `page`;
	wrapper.className = `canvasWrapper`;
	textLayer.className = `textLayer`;
	contoursLayer.className = `contoursLayer`;
	annotationsLayer.className = `annotationsLayer`;
	annotationLayer.className = `annotationLayer`;

	contoursLayer.onclick = (e) => {
			if (!this.viewport || isNaN(this.viewport.scale)) return; 
			let rect = e.target.getBoundingClientRect();
			const scale = this.viewport.scale;
			let x = e.clientX - rect.left; //x position within the element.
			let y = e.clientY - rect.top;  //y position within the element.
			let scaledX = x / scale; //x position within the element.
			let scaledY = y / scale;  //y position within the element.

		};

	page.setAttribute(`data-loaded`, `false`);
	page.setAttribute(`data-page-number`, num);

	canvas.width = width;
	canvas.height = height;
	page.style.width = `${width}px`;
	page.style.height = `${height}px`;
	wrapper.style.width = `${width}px`;
	wrapper.style.height = `${height}px`;
	textLayer.style.width = `${width}px`;
	textLayer.style.height = `${height}px`;
	contoursLayer.style.width = `${width}px`;
	contoursLayer.style.height = `${height}px`;
	annotationsLayer.style.width = `${width}px`;
	annotationsLayer.style.height = `${height}px`;
	annotationLayer.style.width = `${width}px`;
	annotationLayer.style.height = `${height}px`;

	canvas.setAttribute(`id`, `page${num}`);

	page.appendChild(wrapper);
	page.appendChild(textLayer);
	page.appendChild(contoursLayer);
	page.appendChild(annotationsLayer);
	page.appendChild(annotationLayer);
	wrapper.appendChild(canvas);

	return page;
};

// Set events on an element (a contour)
PdfViewer.prototype.setEvents = function(items) {
	let self = this;
	for (let i = 0; i < items.length; i++) {
		let item = items[i];
		let element = item.get(0);
		// the link here goes to the bibliographical reference
		element.onclick = function() {
			let el = $(this);
			if (typeof self.events.onClick === `function`)
				return self.events.onClick({ id: el.attr(`sentence-id`) });
		};
		element.onmouseover = function() {
			let el = $(this);
			self.viewer.find(`.contour[sentence-id="${el.attr(`sentence-id`)}"]`).addClass(`hover`);
			if (typeof self.events.onHover === `function`)
				return self.events.onHover({ id: el.attr(`sentence-id`) });
		};
		element.onmouseout = function() {
			let el = $(this);
			self.viewer
				.find(`.contour[sentence-id="${el.attr(`sentence-id`)}"]`)
				.removeClass(`hover`);
			if (typeof self.events.onEndHover === `function`)
				return self.events.onEndHover({ id: el.attr(`sentence-id`) });
		};
	}
};

// Insert marker in scrollbar
PdfViewer.prototype.addMarker = function(dataObject, sentence) {
	let self = this;
	let contours = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	const screenHeight = this.screenElement.getBoundingClientRect().height || 0;

	return contours.map(function() {
		let alreadyExist = self.scrollMarkers.find(`.marker[sentence-id="${sentence.id}"][dataObject-id="${dataObject._id}"]`);
		let contour = $(this),
			hitbox = contour.find(`.hitbox`).first(),
			spanTop = self._scrollToSentence(sentence) + screenHeight / 2 ,
			spanBottom = spanTop + parseInt(contour.attr(`contour-height`), 10),
			spanLeft = parseInt(contour.attr(`contour-left`), 10),
			spanRight = spanLeft + parseInt(contour.attr(`contour-width`), 10),
			markerTop = Math.floor(
				(spanTop * self.screen.height()) / self.container.prop(`scrollHeight`)
			),
			markerBottom = Math.floor(
				(spanBottom * self.screen.height()) / self.container.prop(`scrollHeight`)
			),
			markerLeft = Math.floor((spanLeft * self.screen.width()) / self.container.width()),
			markerRight = Math.floor((spanRight * self.screen.width()) / self.container.width()),
			markerElement = alreadyExist.length > 0 ? alreadyExist.get(0): document.createElement(`span`),
			marker = $(markerElement),
			coeff = self.scrollMarkers.outerWidth() / self.container.outerWidth();
		
		markerElement.style.backgroundColor = dataObject.color.background.border;
		markerElement.style.top = markerTop + `px`;
		markerElement.style.left = parseInt(markerLeft * coeff, 10) + `px`;
		markerElement.style.height = markerBottom - markerTop + `px`;
		markerElement.style.width = parseInt((markerRight - markerLeft) * coeff, 10) + `px`;
		self.scrollMarkersElement.appendChild(markerElement);
		marker.addClass(`marker`);
		marker.attr(`sentence-id`, sentence.id);
		marker.attr(`dataObject-id`, dataObject._id);
		marker.attr(`kind`, dataObject.dataObjectType);
		marker.attr(`contour-height`, contour.attr(`contour-height`));
		marker.attr(`contour-left`, contour.attr(`contour-left`));
		marker.attr(`contour-width`, contour.attr(`contour-width`));
		marker.attr(`border-color`, dataObject.color.background.border);
		marker.click(function(e) {
			e.stopPropagation();
			return hitbox.click();
		});
	});
};

// Remove marker in scrollbar
PdfViewer.prototype.removeMarker = function(dataObject, sentence) {
	this.scrollMarkers.find(`[sentence-id="${sentence.id}"][dataObject-id="${dataObject._id}"]`).remove();
};

// Update marker in scrollbar
PdfViewer.prototype.updateMarker = function(dataObject, sentence) {
	this.scrollMarkers
		.find(`span[sentence-id="${sentence.id}"][dataObject-id="${dataObject._id}"]`)
		.css(`background-color`, dataObject.color.background.rgb);
};

// Add a link
PdfViewer.prototype.addLink = function(dataObject, sentence, isSelected = true) {
	const self = this;
	if (typeof this.links[dataObject._id] === `undefined`) this.links[dataObject._id] = [];
	this.links[dataObject._id].push(sentence.id);
	this.links[dataObject._id].sort();

	const contour = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	const dataObjectsTypes = contour.attr(`data-objects-types`) ? JSON.parse(contour.attr(`data-objects-types`)) : {};
	dataObjectsTypes[dataObject._id] = dataObject.dataObjectType;

	contour.attr(`data-objects-types`, JSON.stringify(dataObjectsTypes));

	contour.attr(`is-${dataObject.dataObjectType}`, true);

	const contourDataInstanceIds = contour.attr(`data-objects`) ? contour.attr(`data-objects`).split(' ') : [];
	const hasContourDataInstanceId = contourDataInstanceIds.indexOf(`#${dataObject.dataInstanceId}`) > -1;

	// Add DataObjects
	if (!hasContourDataInstanceId) contour.attr(`data-objects`, `${contour.attr(`data-objects`) ? contour.attr(`data-objects`) : ''} #${dataObject.dataInstanceId}`.trim());

	// Annotation data-objects
	let annotation = this.viewer.find(`.annotationsLayer > s[sentence-id="${sentence.id}"]`);
	annotation.attr(`is-${dataObject.dataObjectType}`, true);

	const annotationDataInstanceIds = annotation.attr(`data-objects`) ? annotation.attr(`data-objects`).split(' ') : [];
	const hasAnnotationDataInstanceId = annotationDataInstanceIds.indexOf(` #${dataObject.dataInstanceId}`) > -1;

	if (!hasAnnotationDataInstanceId) annotation.attr(`data-objects`, `${annotation.attr(`data-objects`) ? annotation.attr(`data-objects`) : ""} #${dataObject.dataInstanceId}`);

	// Annotation DataObjects Types
	if (annotation.attr(`data-objects-types`)) {
		annotation.attr(`data-objects-types`, (
			annotation.attr(`data-objects-types`)
				.replace(`${dataObject.dataObjectType}`, ``) + ` ${dataObject.dataObjectType}` )
				.trim()
		);
	} else {
		annotation.attr(`data-objects-types`, `${dataObject.dataObjectType}`);
	}
	this.addMarker( dataObject, sentence );
	this.colorize(sentence, dataObject.color, function () { self.setBorder( sentence, BORDER_WIDTH, isSelected ? dataObject.color.background.border : REMOVED_BORDER_COLOR, !isSelected ); });
};

// Remove a link
PdfViewer.prototype.removeLink = function(dataObject, sentence, callback) {
	const self = this;
	this.links[dataObject._id].splice(this.links[dataObject._id].indexOf(sentence.id), 1);

	const contour = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);

	const dataObjectsTypes = contour.attr(`data-objects-types`) ? JSON.parse(contour.attr(`data-objects-types`)) : {};
	delete dataObjectsTypes[dataObject._id];

	const contourDataInstanceIds = contour.attr(`data-objects`) ? contour.attr(`data-objects`).split(' ') : [];
	const contourDataInstanceFilteredIds = contourDataInstanceIds.filter(function (item) {
		return item !== `#${dataObject.dataInstanceId}`;
	}).join(' ')

	contour.attr(`data-objects`, contourDataInstanceFilteredIds);
	if (contour.attr(`data-objects`) === "") contour.removeAttr(`data-objects`);

	// Handle DataObjects Types remove
	if (Object.keys(dataObjectsTypes).length > 0) {
		contour.attr(`data-objects-types`, JSON.stringify(dataObjectsTypes));
		let hasDeletedDataObjectType = false;
		let lastDataType = null;
		Object.values(dataObjectsTypes).forEach(value => {
			lastDataType = value;
			if (value === dataObject.dataObjectType) hasDeletedDataObjectType = true;
		});
		if (!hasDeletedDataObjectType) contour.removeAttr(`is-${dataObject.dataObjectType}`);
		this.colorize(sentence, DATATYPE_COLORS[lastDataType], function () {
			self.setBorder( sentence, BORDER_WIDTH, DATATYPE_COLORS[lastDataType]?.background.border, !sentence.isSelected );
		});
	} else {
		contour.removeAttr(`data-objects-types`);
		contour.removeAttr(`is-${dataObject.dataObjectType}`);
		this.uncolorize(sentence);
		this.setBorder(sentence, BORDER_WIDTH, DATATYPE_COLORS[this.metadata.activeDataObjectType]?.background.border);
	}

	// remove marker
	this.removeMarker(dataObject, sentence);

	if (contour.attr(`data-objects`) === ``) contour.removeAttr(`data-objects`);

	const annotation = this.viewer.find(`.annotationsLayer > s[sentence-id="${sentence.id}"]`);

	const annotationDataInstanceIds = annotation.attr(`data-objects`) ? annotation.attr(`data-objects`).split(' ') : [];
	const annotationDataInstanceFilteredIds = annotationDataInstanceIds.filter(function (item) {
		return item !== `#${dataObject.dataInstanceId}`;
	}).join(' ')

	annotation.attr( `data-objects`, annotationDataInstanceFilteredIds);
	if (annotation.attr(`data-objects`) === ``) annotation.removeAttr(`data-objects`);

	if (typeof callback === `function`) callback();
};

// Update Link
PdfViewer.prototype.updateLink = function(dataObject, sentence) {
	const contour = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence}"]`);
	const dataObjectsTypes = contour.attr(`data-objects-types`) ? JSON.parse(contour.attr(`data-objects-types`)) : {};
	dataObjectsTypes[dataObject._id] = dataObject.dataObjectType;

	contour.attr(`data-objects-types`, JSON.stringify(dataObjectsTypes));
}

// Update links
PdfViewer.prototype.updateLinks = function(dataObject) {
	const self = this;
	const sentences = dataObject.sentences.map(sentence => sentence.id);
	
	for ( let i = 0; i < sentences.length; i++ ) {
		self.updateLink(dataObject, sentences[i])
	}
}

// Update DataObject
PdfViewer.prototype.updateDataObject = function(dataObject) {
	this.updateLinks(dataObject);
}

// Add a dataObject
PdfViewer.prototype.addDataObject = function(dataObject, sentence, isSelected = true) {
	this.addLink(dataObject, sentence, isSelected);
};

// Remove a dataObject
PdfViewer.prototype.removeDataObject = function (dataObject, callback) {
	const links = this.links[dataObject._id];
	const ids = links ? [...links] : [];

	for (let i = 0; i < ids.length; i++) {
		this.removeLink(dataObject, { id: ids[i] });
	}

	if (typeof callback === `function`) callback();
};

// Refresh all data-objects color depending on active dataObject type
PdfViewer.prototype.refreshDataObjectsColor = function () {
	const self = this;
	const contours = this.viewer.find(`.contoursLayer > .contour[data-objects]`);
	contours.map(function() {
		let el = $(this);
		let sentence = self.getInfoOfSentence({ id : el.attr('sentence-id') });
		let color = undefined;
		if (sentence.dataObjectsTypes.length > 0) {
			if (sentence.dataObjectsTypes.indexOf(self.metadata.activeDataObjectType) > -1) color = DATATYPE_COLORS[self.metadata.activeDataObjectType];
			else color = DATATYPE_COLORS[sentence.dataObjectsTypes[0]];
		}
		if (color === undefined) return;
			self.colorize(sentence, color, function () {
				self.setBorder( sentence, BORDER_WIDTH, color?.background.border, !sentence.isSelected );
			});
		})
};

// Refresh all markes color depending on active dataObject type
PdfViewer.prototype.refreshMarkersColor = function () {
	const self = this;
	this.scrollMarkers.find(`.marker.active`).removeClass('active');
	this.scrollMarkers.find(`.marker[kind="${this.metadata.activeDataObjectType}"]`).addClass('active');
};

// Scroll to a sentence
PdfViewer.prototype.scrollToDataObject = function(dataObject) {
	let element = this.viewer.find(`s[dataObject-id="${dataObject._id}"]`).first(),
		numPage = parseInt(
			element
				.parent()
				.parent()
				.attr(`data-page-number`),
			10
		),
		pages = this.viewer.find(`div[class="page"]`).sort(function(a, b) {
			let aPage = parseInt($(a).attr(`data-page-number`), 10),
				bPage = parseInt($(b).attr(`data-page-number`), 10);
			return aPage - bPage;
		}),
		height = 0;
	for (let i = 0; i < pages.length; i++) {
		let page = pages[i],
			el = $(page),
			currentNumPage = parseInt(el.attr(`data-page-number`), 10);
		if (currentNumPage === numPage) break;
		height += el.outerHeight(true);
	}
	this.currentPage = numPage;
	return height + element.position().top;
};

// Select a sentence
PdfViewer.prototype.scrollToSentence = function(sentence, cb) {
	let self = this,
		pages = this.getPagesOfSentence(sentence),
		allPages = this.getPages(),
		maxPage = Math.max(...pages),
		minPage = Math.min(...pages);
	if (maxPage > 0) {
		let numPages = [];
		if (maxPage > 1) numPages.push(maxPage - 1);
		numPages.push(maxPage);
		if (allPages.length > 2 && maxPage < allPages[allPages.length - 2])
			numPages.push(maxPage + 1);
		this.renderPages(numPages, function(err, res) {
			if (err) return cb(err);
			self.currentPage = minPage;
			let scrollTop = self._scrollToSentence(sentence);
			return cb(scrollTop);
		});
	} else return cb(new Error(`invalid sentence id`));
};

// Scroll to a sentence
PdfViewer.prototype._scrollToSentence = function(sentence) {
	const element = this.viewer.find(`s[sentence-id="${sentence.id}"]`).first();
	const numPage = parseInt(
			element
				.parent()
				.parent()
				.attr(`data-page-number`),
			10
		);
	const pages = this.viewer.find(`div[class="page"]`).sort(function(a, b) {
		let aPage = parseInt($(a).attr(`data-page-number`), 10),
			bPage = parseInt($(b).attr(`data-page-number`), 10);
		return aPage - bPage;
	});
	const screenHeight = this.screenElement.getBoundingClientRect().height || 0;
	let height = 0;
		
	for (let i = 0; i < pages.length; i++) {
		let page = pages[i],
			el = $(page),
			currentNumPage = parseInt(el.attr(`data-page-number`), 10);
		if (currentNumPage === numPage) break;
		
		height += el.outerHeight(true);
	}
	return height + element.position().top - (screenHeight / 2);
};

// Scroll to a sentence
PdfViewer.prototype._scrollToDASSentence = function(sentence) {
	const element = this.DASViewer.find(`s[sentence-id="${sentence.id}"]`).first();
	const numPage = parseInt(
			element
				.parent()
				.parent()
				.attr(`data-page-number`),
			10
		);
	const pages = this.DASViewer.find(`div[class="page"]`).sort(function(a, b) {
		let aPage = parseInt($(a).attr(`data-page-number`), 10),
			bPage = parseInt($(b).attr(`data-page-number`), 10);
		return aPage - bPage;
	});
	const screenHeight = this.DASScreenElement.getBoundingClientRect().height || 0;
	let height = 0;
		
	for (let i = 0; i < pages.length; i++) {
		let page = pages[i],
			el = $(page),
			currentNumPage = parseInt(el.attr(`data-page-number`), 10);
		if (currentNumPage === numPage) break;
		
		height += el.outerHeight(true);
	}
	let position = height + element.position().top - 15;
	this.DASScreen.scrollTop(position);
};

// Scroll to a sentence
PdfViewer.prototype.scrollToPage = function(numPage) {
	const pages = this.viewer.find(`div[class="page"]`).sort(function(a, b) {
		let aPage = parseInt($(a).attr(`data-page-number`), 10),
			bPage = parseInt($(b).attr(`data-page-number`), 10);
		return aPage - bPage;
	});
	const screenHeight = this.screenElement.getBoundingClientRect().height || 0;
	let height = 0;
	let lastNumPage = 0;

	for (let i = 0; i < pages.length; i++) {
		let page = pages[i],
			el = $(page),
			currentNumPage = parseInt(el.attr(`data-page-number`), 10);
			lastNumPage = currentNumPage;
		if (currentNumPage === numPage) break;

		height += el.outerHeight(true);
	}
	this.currentPage = lastNumPage;

	return height;
};


PdfViewer.prototype.getInfoOfSentence = function(sentence) {
	let result = {};
	let el = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	if (el.get(0)) {
		let hasDataObjects = typeof el.attr(`data-objects`) !== `undefined`;
		let	dataInstanceIds = hasDataObjects ? el.attr(`data-objects`).replace(/#/gm, ``).split(` `) : [];
		result.id = el.attr(`sentence-id`);
		result.dataInstanceIds = dataInstanceIds;
		result.hasDataObjects = hasDataObjects;
		result.isSelected = el.hasClass(`selected`);
		result.isDataset = el.attr(`is-dataset`) === "true";
		result.isCode = el.attr(`is-code`) ===  "true";
		result.isMaterial = el.attr(`is-material`) === "true";
		result.isProtocol = el.attr(`is-protocol`) === "true";
		result.text = el.text();
		result.index = parseInt(el.attr(`index`), 10);
		result.dataObjectsTypes = [];
		if (result.isDataset) result.dataObjectsTypes.push("dataset")
		if (result.isCode) result.dataObjectsTypes.push("code")
		if (result.isMaterial) result.dataObjectsTypes.push("material")
		if (result.isProtocol) result.dataObjectsTypes.push("protocol")
	}
	return result;
};

PdfViewer.prototype.getInfoOfFirstSentence = function(sentence) {
	return this.getInfoOfSentence({ id: this.sentencesMapping.array[0] });
}

// selectSentence
PdfViewer.prototype.selectSentence = function(sentence) {
	let sentenceElement = this.viewer.find(`s[sentence-id="${sentence.id}"]`),
		contourElement = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	if (sentenceElement) sentenceElement.addClass(`selected`);
	if (contourElement) {
		contourElement.addClass(`selected`);
		this.selectCanvas(sentence);
	}
};

// unselectSentence
PdfViewer.prototype.unselectSentence = function(sentence) {
	let sentenceElement = this.viewer.find(`s[sentence-id="${sentence.id}"]`),
		contourElement = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	if (sentenceElement) sentenceElement.removeClass(`selected`);
	if (contourElement) {
		contourElement.removeClass(`selected`);
		this.unselectCanvas(sentence);
	}
};

// hoverSentence
PdfViewer.prototype.hoverSentence = function(sentence) {
	return this.hoverCanvas(sentence);
};

// endHoverSentence
PdfViewer.prototype.endHoverSentence = function(sentence) {
	this.endHoverCanvas(sentence);
};

// displayLeft
PdfViewer.prototype.displayLeft = function() {
	this.pagesInfo.removeClass().addClass(`display-left`);
	this.message.removeClass().addClass(`display-left`);
	this.scrollMarkers.removeClass().addClass(`display-left`);
};

// displayRight
PdfViewer.prototype.displayRight = function() {
	this.pagesInfo.removeClass().addClass(`display-right`);
	this.message.removeClass().addClass(`display-right`);
	this.scrollMarkers.removeClass().addClass(`display-right`);
};

// Build borders
PdfViewer.prototype.selectCanvas = function (sentence) {
	const activeDataObjectTypeColor = DATATYPE_COLORS[this.metadata.activeDataObjectType]?.background.border || SELECTED_BORDER_COLOR;

	this.setBorder(
		sentence,
		SELECTED_BORDER_WIDTH,
		activeDataObjectTypeColor,
		!sentence.isSelected
	);
};

// Build borders
PdfViewer.prototype.unselectCanvas = function (sentence) {
	let color = undefined;
	if (sentence.dataObjectsTypes.length > 0) {
		if (sentence.dataObjectsTypes.indexOf(this.metadata.activeDataObjectType) > -1) color = DATATYPE_COLORS[this.metadata.activeDataObjectType]?.background.border;
		else color = DATATYPE_COLORS[sentence.dataObjectsTypes[0]]?.background.border;
	}
	if (color === undefined) color = REMOVED_BORDER_COLOR;
	this.setBorder(
		sentence,
		sentence.isSelected ? SELECTED_BORDER_WIDTH : BORDER_WIDTH,
		color,
		!sentence.isSelected
	);
};

// Build borders
PdfViewer.prototype.hoverCanvas = function (sentence) {
	const isSelected = sentence.isSelected;
	const activeDataObjectTypeColor = DATATYPE_COLORS[this.metadata.activeDataObjectType]?.background.border || SELECTED_BORDER_COLOR;

	if (isSelected) return
	
	this.setBorder(
		sentence,
		BORDER_WIDTH,
		activeDataObjectTypeColor,
		false
	);
	this.displayCanvas(sentence);
};

// Build borders
PdfViewer.prototype.endHoverCanvas = function (sentence) {
	const isSelected = sentence.isSelected;
	let color = undefined;
	if (sentence.dataObjectsTypes.length > 0) {
		if (sentence.dataObjectsTypes.indexOf(this.metadata.activeDataObjectType) > -1) color = DATATYPE_COLORS[this.metadata.activeDataObjectType]?.background.border;
		else color = DATATYPE_COLORS[sentence.dataObjectsTypes[0]]?.background.border;
	}
	if (color === undefined) color = REMOVED_BORDER_COLOR;

	this.hideCanvas(sentence);

	if (isSelected) return;

	this.setBorder(
		sentence,
		BORDER_WIDTH,
		color,
		!isSelected
	);
};

// Get ImageData of a Canvas
PdfViewer.prototype.getImageData = function(data, sentence, key) {
	let p = data.p;
	let w = data.w;
	let h = data.h;
	let y = data.y;
	let x = data.x;
	let canvas = this.viewer.find(`canvas[id=page${p}]`).get(0);
	let contextNotBuffered = typeof CANVAS_CONTEXT_BUFFER[p] === "undefined";
	let imageDataNotBuffered = typeof CANVAS_IMAGE_DATA_BUFFER[p] === "undefined"
		|| typeof CANVAS_IMAGE_DATA_BUFFER[p][sentence.id] === "undefined"
		|| typeof CANVAS_IMAGE_DATA_BUFFER[p][sentence.id][key] === "undefined";
	let ctx = contextNotBuffered ? canvas.getContext(`2d`) : CANVAS_CONTEXT_BUFFER[p];
	if (contextNotBuffered) CANVAS_CONTEXT_BUFFER[p] = ctx;
	let imageData = imageDataNotBuffered ? ctx.getImageData(x, y, w, h) : CANVAS_IMAGE_DATA_BUFFER[p][sentence.id][key];
	if (imageDataNotBuffered) {
		if (typeof CANVAS_IMAGE_DATA_BUFFER[p] === "undefined") CANVAS_IMAGE_DATA_BUFFER[p] = {};
		if (typeof CANVAS_IMAGE_DATA_BUFFER[p][sentence.id] === "undefined") CANVAS_IMAGE_DATA_BUFFER[p][sentence.id] = {};
		CANVAS_IMAGE_DATA_BUFFER[p][sentence.id][key] = imageData;
	}
	return imageData;
};

// Build the path
PdfViewer.prototype.buildPath = function(pathString) {
	let path = new Path2D();
	let chunks = pathString.split(' ');
	for (let i = 0; i < chunks.length; i += 3) {
		let instruction = chunks[i];
		let _x = chunks[i + 1];
		let _y = chunks[i + 2];
		if (instruction === "M") path.moveTo(_x, _y);
		if (instruction === "L") path.lineTo(_x, _y);
	}
	return path;
}

// Set the path to the given context
PdfViewer.prototype.setPathToContext = function(ctx, borders) {
	for (let i = 0; i < borders.length; i++) {
		let border = borders[i];
		let chunks = border.split(' ');
		for (let j = 0; j < chunks.length; j += 3) {
			let instruction = chunks[j];
			let _x = chunks[j + 1];
			let _y = chunks[j + 2];
			if (j === 0) ctx.moveTo(_x, _y);
			ctx.lineTo(_x, _y);
		}
		ctx.lineTo(chunks[1], chunks[2])
	}
}

// Display Canvas
PdfViewer.prototype.displayCanvas = function (sentence) {
	let self = this;
	const contours = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	contours.map(function(i) {
		const contour =  $(this);
		const hitBoxes = contour.find(`.hitbox`);
		let done = contour.find('canvas').get().length > 0;
		if (done) return;
		hitBoxes.map(function(j) {
			let hitBox = $(this);
			let p = parseInt(hitBox.attr(`hitbox-page`), 10);
			let w = parseInt(hitBox.attr(`hitbox-width`), 10) + (BORDER_WIDTH * 2);
			let h = parseInt(hitBox.attr(`hitbox-height`), 10) + (BORDER_WIDTH * 2);
			let y = parseInt(hitBox.attr(`hitbox-top`), 10) - BORDER_WIDTH;
			let x = parseInt(hitBox.attr(`hitbox-left`), 10) - BORDER_WIDTH;
			let key = `${i}-${j}`;
			let imageData = self.getImageData({ p, w, h, y, x }, sentence, key);
			let canvas = document.createElement('canvas');
			let ctx = canvas.getContext('2d');
			canvas.width = w;
			canvas.height = h;
			canvas.style = `position:absolute; top:${y}px; left:${x}px; width: ${w}px; height: ${h}px`;
			ctx.putImageData(imageData, 0, 0);
			canvas.className = 'background';
			contour.append(canvas);
		})
	})
}

// Hide Canvas
PdfViewer.prototype.hideCanvas = function (sentence) {
	let contours = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	let canvas = contours.find('canvas');
	return canvas.remove();
}

// Colorize image
PdfViewer.prototype.colorize = function (sentence, color, cb) {
	const self = this;
	const contour = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);

	async.mapSeries(
			contour
				.find(`.border[sentence-id="${sentence.id}"] path`)
				.map(function () {
					return $(this);
				})
				.get(),
			function (border, next) {
					border.attr('stroke', color.background.border);
					return next();
			},
			function () {
				contour.attr(`background-color`, color.background.rgb);
				contour.attr(`border-color`, color.background.border);
				return typeof cb === `function` ? cb() : undefined;
			}
		);
};


// Uncolorize image
PdfViewer.prototype.uncolorize = function (sentence) {
	const self = this;
	const contour = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	
	contour.find(`.border[sentence-id="${sentence.id}"]`).map(function () {
		let border = $(this);
		border.attr('stroke', 'transparent');
	});
	contour.removeAttr(`background-color`);
	contour.removeAttr(`foreground-color`);
};

// Set color to a canvas
PdfViewer.prototype.setBorder = function(sentence, width, borderColor, dashed) {
	let self = this,
		contour = this.viewer.find(`.contoursLayer > .contour[sentence-id="${sentence.id}"]`);
	contour.find(`.border[sentence-id="${sentence.id}"] path`).map(function() {
		let border = $(this);
		border.attr('stroke', borderColor);
		border.attr('stroke-width', width);
		if (dashed) border.attr('stroke-dasharray', STROKE_DASHARRAY);
		else border.removeAttr('stroke-dasharray')
	});
};

// Build canvas
PdfViewer.prototype.buildHitBoxesElements = function(p, _x, _y, _w, _h, sentence) {
	let x = Math.floor(_x),
		y = Math.floor(_y),
		w = Math.floor(_w),
		h = Math.floor(_h);
	let result = $(`<div
		class="hitbox" style="position:absolute; top:${y}px; left:${x}px; width: ${w}px; height: ${h}px"
		sentence-id="${sentence.id}"></div>`);
	result.attr(`hitbox-page`, p);
	result.attr(`hitbox-width`, w);
	result.attr(`hitbox-height`, h);
	result.attr(`hitbox-top`, y);
	result.attr(`hitbox-left`, x);
	return result;
};

// Build canvas
PdfViewer.prototype.buildBordersElements = function(p, _x, _y, _w, _h, sentence, borders = []) {
	let x = Math.floor(_x),
		y = Math.floor(_y),
		w = Math.floor(_w),
		h = Math.floor(_h);
	let svg = $(`<svg
		class="border"
		width="${w}"
		height="${h}"
		viewBox="0 0 ${w} ${h}"
		style="position:absolute; top:${y}px; left:${x}px;"
		xmlns="http://www.w3.org/2000/svg"
		sentence-id="${sentence.id}"
		page=${p}>
			<path sentence-id="${sentence.id}" d="${PdfViewer.getPathData(borders)}" fill="none" stroke="transparent" stroke-linecap="square" ></path>
		</svg>`);
	return svg;
};

PdfViewer.getPathData = function(borders, type) {
	let result = "";
	for (let i = 0; i < borders.length; i++) {
		let border = borders[i];
		result += `M ${border.x0} ${border.y0} L ${border.x1} ${border.y1} `;
	}
	return result.replace(/\s+/gm, ' ');
}

PdfViewer.getPoints = function(borders) {
	let result = "";
	for (let i = 0; i < borders.length; i++) {
		let border = borders[i];
		result += `${border.x0},${border.y0} ${border.x1},${border.y1} `;
	}
	return result.replace(/\s+/gm, ' ');
}

PdfViewer.makeSVG = function(tag, attrs) {
		var el= document.createElementNS('http://www.w3.org/2000/svg', tag);
		for (var k in attrs)
				el.setAttribute(k, attrs[k]);
		return el;
}

// Get squares of given area (usefull to display borders)
PdfViewer.prototype.getSentenceOverlay = function(area) {
	let self = this,
		lines = area.getLines(),
		container = {
			min: { x: Infinity, y: Infinity },
			max: { x: -Infinity, y: -Infinity },
			w: 0,
			h: 0
		},
		hitBoxes = [],
		borders = [];
	let sortedLines = lines.sort(function(a, b) {
		if (a.min.y === b.min.y) {
			return a.min.x < b.min.x;
		} else return a.min.y < b.min.y;
	});
	// calculate min/max values
	for (let i = 0; i < sortedLines.length; i++) {
		let square = sortedLines[i],
			min = {
				x: square.min.x,
				y: square.min.y
			},
			max = {
				x: square.max.x,
				y: square.max.y
			};
		container.min.x = min.x < container.min.x ? min.x : container.min.x;
		container.min.y = min.y < container.min.y ? min.y : container.min.y;
		container.max.x = max.x > container.max.x ? max.x : container.max.x;
		container.max.y = max.y > container.max.y ? max.y : container.max.y;
	}
	// height & width
	container.w = container.max.x - container.min.x;
	container.h = container.max.y - container.min.y;
	// Consctruction du contour
	let firstLine = sortedLines[0],
		secondLine = sortedLines.length === 1 ? sortedLines[0] : sortedLines[1],
		beforeLastLine =
			sortedLines.length < 2
				? sortedLines[sortedLines.length - 1]
				: sortedLines[sortedLines.length - 2],
		lastLine = sortedLines[sortedLines.length - 1],
		centerSquare = {};
	// calculate center square corners
	centerSquare.topLeftCorner = {
		x: firstLine.min.x,
		y: Math.round((secondLine.min.y + firstLine.max.y) / 2)
	};
	centerSquare.bottomRightCorner = {
		x: lastLine.max.x,
		y: Math.round((lastLine.min.y + beforeLastLine.max.y) / 2)
	};
	centerSquare.topRightCorner = {
		x: centerSquare.bottomRightCorner.x,
		y: centerSquare.topLeftCorner.y
	};
	centerSquare.bottomLeftCorner = {
		x: centerSquare.topLeftCorner.x,
		y: centerSquare.bottomRightCorner.y
	};
	// "round" shape of contour
	if (Math.abs(centerSquare.topLeftCorner.x - container.min.x) <= 2 * BORDER_WIDTH)
		centerSquare.topLeftCorner.x = container.min.x;
	if (Math.abs(centerSquare.topLeftCorner.y - container.min.y) <= 2 * BORDER_WIDTH)
		centerSquare.topLeftCorner.y = container.min.y;
	if (Math.abs(centerSquare.topRightCorner.x - container.max.x) <= 2 * BORDER_WIDTH)
		centerSquare.topRightCorner.x = container.max.x;
	if (Math.abs(centerSquare.topRightCorner.y - container.min.y) <= 2 * BORDER_WIDTH)
		centerSquare.topRightCorner.y = container.min.y;
	if (Math.abs(centerSquare.bottomLeftCorner.x - container.min.x) <= 2 * BORDER_WIDTH)
		centerSquare.bottomLeftCorner.x = container.min.x;
	if (Math.abs(centerSquare.bottomLeftCorner.y - container.max.y) <= 2 * BORDER_WIDTH)
		centerSquare.bottomLeftCorner.y = container.max.y;
	if (Math.abs(centerSquare.bottomRightCorner.x - container.max.x) <= 2 * BORDER_WIDTH)
		centerSquare.bottomRightCorner.x = container.max.x;
	if (Math.abs(centerSquare.bottomRightCorner.y - container.max.y) <= 2 * BORDER_WIDTH)
		centerSquare.bottomRightCorner.y = container.max.y;
	// Detect shape of contour-
	if (
		sortedLines.length === 2 &&
		centerSquare.bottomRightCorner.x < centerSquare.topLeftCorner.x
	) {
		/*
		 * case:
		 * 0 1
		 * 1 0
		 * -> 2 canvas on 2 lines
		 * shape is 2 different squares
		 */
		hitBoxes.push({
			min: { x: firstLine.min.x, y: container.min.y },
			max: { x: container.max.x, y: firstLine.max.y },
		});
		hitBoxes.push({
			min: { x: lastLine.min.x, y: lastLine.min.y },
			max: { x: lastLine.max.x, y: container.max.y }
		});
		borders.push([
			{ x0: container.max.x, y0: firstLine.max.y, x1: firstLine.min.x, y1: firstLine.max.y },
			{ x0: firstLine.min.x, y0: firstLine.max.y, x1: firstLine.min.x, y1: container.min.y },
			{ x0: firstLine.min.x, y0: container.min.y, x1: container.max.x, y1: container.min.y }
		]);
		borders.push([
			{ x0: lastLine.min.x, y0: lastLine.min.y, x1: lastLine.max.x, y1: lastLine.min.y },
			{ x0: lastLine.max.x, y0: lastLine.min.y, x1: lastLine.max.x, y1: container.max.y },
			{ x0: lastLine.max.x, y0: container.max.y, x1: lastLine.min.x, y1: container.max.y }
		]);
	} else if (
		sortedLines.length === 1 ||
		(centerSquare.topLeftCorner.x === container.min.x &&
			centerSquare.bottomLeftCorner.x === container.min.x &&
			centerSquare.topRightCorner.x === container.max.x &&
			centerSquare.bottomRightCorner.x === container.max.x)
	) {
		/*
		 * case:
		 * 1 1
		 * 1 1
		 * -> 1 hitBoxes on 1 to N lines
		 * shape is a square
		 */
		hitBoxes.push({
			min: { x: container.min.x, y: container.min.y },
			max: { x: container.max.x, y: container.max.y }
		});
		borders.push([
			{ x0: container.min.x, y0: container.min.y, x1: container.max.x, y1: container.min.y },
			{ x0: container.max.x, y0: container.min.y, x1: container.max.x, y1: container.max.y },
			{ x0: container.max.x, y0: container.max.y, x1: container.min.x, y1: container.max.y },
			{ x0: container.min.x, y0: container.max.y, x1: container.min.x, y1: container.min.y }
		]);
	} else if (
		centerSquare.topLeftCorner.x === container.min.x &&
		centerSquare.bottomLeftCorner.x === container.min.x &&
		centerSquare.topRightCorner.x < container.max.x &&
		centerSquare.bottomRightCorner.x < container.max.x
	) {
		/*
		 * case:
		 * 1 1
		 * 1 0
		 * -> 2 hitBoxes
		 */
		hitBoxes.push({
			min: { x: container.min.x, y: container.min.y },
			max: { x: container.max.x, y: centerSquare.bottomRightCorner.y }
		});
		hitBoxes.push({
			min: { x: container.min.x, y: centerSquare.bottomRightCorner.y },
			max: { x: centerSquare.bottomRightCorner.x, y: container.max.y }
		});
		borders.push([
			{ x0: container.min.x, y0: centerSquare.bottomRightCorner.y, x1: container.min.x, y1: container.min.y },
			{ x0: container.min.x, y0: container.min.y, x1: container.max.x, y1: container.min.y },
			{ x0: container.max.x, y0: container.min.y, x1: container.max.x, y1: centerSquare.bottomRightCorner.y },
			{ x0: container.max.x, y0: centerSquare.bottomRightCorner.y, x1: centerSquare.bottomRightCorner.x, y1: centerSquare.bottomRightCorner.y }
		]);
		borders.push([
			{ x0: container.min.x, y0: centerSquare.bottomRightCorner.y, x1: container.min.x, y1: container.max.y },
			{ x0: container.min.x, y0: container.max.y, x1: centerSquare.bottomRightCorner.x, y1: container.max.y },
			{ x0: centerSquare.bottomRightCorner.x, y0: container.max.y, x1: centerSquare.bottomRightCorner.x, y1: centerSquare.bottomRightCorner.y }
		]);
	} else if (
		centerSquare.topLeftCorner.x > container.min.x &&
		centerSquare.bottomLeftCorner.x > container.min.x &&
		centerSquare.topRightCorner.x === container.max.x &&
		centerSquare.bottomRightCorner.x === container.max.x
	) {
		/*
		 * case:
		 * 0 1
		 * 1 1
		 * -> 2 hitBoxes
		 */
		hitBoxes.push({
			min: { x: centerSquare.topLeftCorner.x, y: container.min.y },
			max: { x: container.max.x, y: centerSquare.topLeftCorner.y }
		});
		hitBoxes.push({
			min: { x: container.min.x, y: centerSquare.topLeftCorner.y },
			max: { x: container.max.x, y: container.max.y }
		});
		borders.push([
			{ x0: centerSquare.topLeftCorner.x, y0: centerSquare.topLeftCorner.y, x1: centerSquare.topLeftCorner.x, y1: container.min.y },
			{ x0: centerSquare.topLeftCorner.x, y0: container.min.y, x1: container.max.x, y1: container.min.y },
			{ x0: container.max.x, y0: container.min.y, x1: container.max.x, y1: centerSquare.topLeftCorner.y }
		])
		borders.push([
			{ x0: centerSquare.topLeftCorner.x, y0: centerSquare.topLeftCorner.y, x1: container.min.x, y1: centerSquare.topLeftCorner.y },
			{ x0: container.min.x, y0: centerSquare.topLeftCorner.y, x1: container.min.x, y1: container.max.y },
			{ x0: container.min.x, y0: container.max.y, x1: container.max.x, y1: container.max.y },
			{ x0: container.max.x, y0: container.max.y, x1: container.max.x, y1: centerSquare.topRightCorner.y }
		]);
	} else {
		if (centerSquare.topLeftCorner.x > centerSquare.bottomRightCorner.x) {
			/*
			 * case:
			 * 0 0 1
			 * 1 1 1
			 * 1 0 0
			 * 3 hitBoxes
			 */
			// Be careful, here topLeftCorner is on the right of topRightCorner, so do not trust variable names
			hitBoxes.push({
				min: { x: centerSquare.topLeftCorner.x, y: container.min.y },
				max: { x: container.max.x, y: centerSquare.topLeftCorner.y },
			});
			// Be careful, here topLeftCorner is on the right of topRightCorner, so do not trust variable names
			hitBoxes.push({
				min: { x: container.min.x, y: centerSquare.topLeftCorner.y },
				max: { x: container.max.x, y: centerSquare.bottomRightCorner.y },
			});
			// Be careful, here topLeftCorner is on the right of topRightCorner, so do not trust variable names
			hitBoxes.push({
				min: { x: container.min.x, y: centerSquare.bottomRightCorner.y },
				max: { x: centerSquare.bottomRightCorner.x, y: container.max.y },
			});
			borders.push([
				{ x0: centerSquare.topLeftCorner.x, y0: centerSquare.topLeftCorner.y, x1: centerSquare.topLeftCorner.x, y1: container.min.y },
				{ x0: centerSquare.topLeftCorner.x, y0: container.min.y, x1: container.max.x, y1: container.min.y },
				{ x0: container.max.x, y0: container.min.y, x1: container.max.x, y1: centerSquare.topLeftCorner.y }
			]);
			borders.push([
				{ x0: centerSquare.topLeftCorner.x, y0: centerSquare.topLeftCorner.y, x1: container.min.x, y1: centerSquare.topLeftCorner.y },
				{ x0: container.min.x, y0: centerSquare.topLeftCorner.y, x1: container.min.x, y1: centerSquare.bottomRightCorner.y },
				{ x0: centerSquare.bottomRightCorner.x, y0: centerSquare.bottomRightCorner.y, x1: container.max.x, y1: centerSquare.bottomRightCorner.y },
				{ x0: container.max.x, y0: centerSquare.bottomRightCorner.y, x1: container.max.x, y1: centerSquare.topLeftCorner.y }
			]);
			borders.push([
				{ x0: container.min.x, y0: centerSquare.bottomRightCorner.y, x1: container.min.x, y1: container.max.y },
				{ x0: container.min.x, y0: container.max.y, x1: centerSquare.bottomRightCorner.x, y1: container.max.y },
				{ x0: centerSquare.bottomRightCorner.x, y0: container.max.y, x1: centerSquare.bottomRightCorner.x, y1: centerSquare.bottomRightCorner.y }
			]);
		} else {
			/*
			 * case:
			 * 0 1 1
			 * 1 1 1
			 * 1 1 0
			 * 2 hitBoxes
			 */
			hitBoxes.push({
				min: { x: centerSquare.topLeftCorner.x, y: container.min.y },
				max: { x: container.max.x, y: centerSquare.bottomRightCorner.y },
			});
			hitBoxes.push({
				min: { x: container.min.x, y: centerSquare.topLeftCorner.y },
				max: { x: centerSquare.bottomRightCorner.x, y: container.max.y },
			});
			borders.push([
				{ x0: centerSquare.topLeftCorner.x, y0: centerSquare.topLeftCorner.y, x1: centerSquare.topLeftCorner.x, y1: container.min.y },
				{ x0: centerSquare.topLeftCorner.x, y0: container.min.y, x1: container.max.x, y1: container.min.y },
				{ x0: container.max.x, y0: container.min.y, x1: container.max.x, y1: centerSquare.bottomRightCorner.y },
				{ x0: container.max.x, y0: centerSquare.bottomRightCorner.y, x1: centerSquare.bottomRightCorner.x, y1: centerSquare.bottomRightCorner.y }
			]);
			borders.push([
				{ x0: centerSquare.topLeftCorner.x, y0: centerSquare.topLeftCorner.y, x1: container.min.x, y1: centerSquare.topLeftCorner.y },
				{ x0: container.min.x, y0: centerSquare.topLeftCorner.y, x1: container.min.x, y1: container.max.y },
				{ x0: container.min.x, y0: container.max.y, x1: centerSquare.bottomRightCorner.x, y1: container.max.y },
				{ x0: centerSquare.bottomRightCorner.x, y0: container.max.y, x1: centerSquare.bottomRightCorner.x, y1: centerSquare.bottomRightCorner.y }
			]);
		}
	}
	// update hitBoxes coordinates values relative to the container
	let hitBoxesInfo = hitBoxes.map(function(item) {
		let w = item.max.x - item.min.x;
		let h = item.max.y - item.min.y;
		return { elements: self.buildHitBoxesElements(area.p, item.min.x, item.min.y, w, h, area.sentence) };
	});
	let bordersData = borders.map(function(bordersGroup) {
		return bordersGroup.map(function(border) {
			return {
				x0: border.x0 - (container.min.x - BORDER_WIDTH),
				y0: border.y0 - (container.min.y - BORDER_WIDTH),
				x1: border.x1 - (container.min.x - BORDER_WIDTH),
				y1: border.y1 - (container.min.y - BORDER_WIDTH)
			};
		})
	}).flat();
	let bordersElements = [
		self.buildBordersElements(area.p, container.min.x - BORDER_WIDTH, container.min.y - BORDER_WIDTH, container.w + (BORDER_WIDTH * 2), container.h + (BORDER_WIDTH * 2), area.sentence, bordersData)
	];
	// set events on hitBoxes
	this.setEvents(hitBoxesInfo.map((item) => { return item.elements; }));
	return { hitBoxes: hitBoxesInfo, borders: { data : bordersData, elements: bordersElements } };
};

// Change active dataObject type
PdfViewer.prototype.setActiveDataObjectType = function(dataObjectType) {
	const self = this;
	const links = this.links;
	self.metadata.activeDataObjectType = dataObjectType;
	self.refreshDataObjectsColor();
	self.refreshMarkersColor();
}

export default PdfViewer;
